<script lang="ts" setup>
import type { PlayersActivity, PlayersActivityGame } from "@/types";

const defaultTypesDictionary = new Map([
	["jackpot", "jackpot"],
	["daily-wheel-accrual", "fortune wheel"]
]);

const props = defineProps<{ winner: PlayersActivity }>();
defineEmits<{ (event: "openGame", item: PlayersActivityGame): void }>();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { isMobile } = useDevice();
const isGuest = useIsGuest();

const imageSrcPath = computed(() => {
	if (defaultTypesDictionary.has(props.winner.type)) {
		return `/nuxt-img/activity-feed/${props.winner.type}.png`;
	}

	return `${baseImageUrl}${props.winner.data?.logo}`;
});
</script>
<template>
	<div class="feed-card">
		<AAnimationSkeleton v-if="'skeleton' in winner" class="skeleton-wrapper">
			<ASkeleton width="100%" height="100%" />
		</AAnimationSkeleton>

		<template v-else>
			<div class="content" @click="$emit('openGame', winner.data)">
				<NuxtImg
					class="logo"
					:src="imageSrcPath"
					format="webp"
					:alt="winner.type"
					:data-tid="winner?.data?.slug"
					loading="lazy"
					quality="100"
				/>
				<div class="info">
					<AText
						class="winner-name text-cocoa"
						variant="topeka"
						data-tid="winner-name"
						:modifiers="['ellipsis', 'semibold', 'uppercase']"
					>
						{{ winner.name || winner.nickname }} {{ winner.surname }}
					</AText>
					<AText class="winner-title" variant="topeka" :modifiers="['semibold', 'condensed', 'uppercase']" as="div">
						<i18n-t
							v-if="defaultTypesDictionary.has(winner.type)"
							:keypath="defaultTypesDictionary.get(winner.type) ?? ''"
						>
							<template #key><br /></template>
						</i18n-t>
						<div v-else v-html="winner.data?.title" />
					</AText>

					<div class="prize-pool" :class="{ column: winner.type === 'daily-wheel-accrual' }">
						<MPrizeFund
							v-if="winner.type === 'win'"
							:variant="winner.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
							is-svg
							:iconSize="isMobile ? 16 : 18"
							:icon="winner.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
						>
							<AText
								:variant="isMobile ? 'topeka' : 'toledo'"
								:modifiers="['semibold']"
								:data-tid="`win-amount-${winner?.gameMode}`"
							>
								{{ numberFormat(winner?.data?.winAmount ?? 0) }}
							</AText>
						</MPrizeFund>

						<MPrizeFund
							v-if="winner.type === 'jackpot'"
							:variant="winner.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
							is-svg
							:iconSize="isMobile ? 16 : 18"
							:icon="winner.gameMode === 'SweepStakes' && !isGuest ? 'entries' : 'coins'"
						>
							<AText
								:variant="isMobile ? 'topeka' : 'toledo'"
								:modifiers="['semibold']"
								:data-tid="`jackpot-amount-${winner?.gameMode}`"
							>
								{{ numberFormat(winner?.data?.amount ?? 0) }}
							</AText>
						</MPrizeFund>

						<MPrizeFund
							v-if="winner.type === 'bigwin'"
							is-svg
							variant="coins"
							:iconSize="isMobile ? 16 : 18"
							:icon="winner.gameMode === 'SweepStakes' && !isGuest ? `games/big-win-fc` : `games/big-win-gc`"
						>
							<AText
								:variant="isMobile ? 'topeka' : 'toledo'"
								:modifiers="['semibold']"
								:data-tid="`bigwin-amount-${winner?.gameMode}`"
							>
								{{ numberFormat(winner?.data?.big_win_coefficient ?? 0) }}
							</AText>
						</MPrizeFund>

						<template v-if="winner.type === 'daily-wheel-accrual'">
							<MPrizeFund v-if="winner?.data?.coins" variant="coins" is-svg :iconSize="isMobile ? 16 : 18" icon="coins">
								<AText
									:variant="isMobile ? 'topeka' : 'toledo'"
									:modifiers="['semibold']"
									:data-tid="`daily-wheel-amount-${winner?.gameMode}`"
								>
									{{ numberFormat(winner.data.coins) }}
								</AText>
							</MPrizeFund>
							<MPrizeFund
								v-if="winner?.data?.entries && !isGuest"
								variant="entries"
								is-svg
								:iconSize="isMobile ? 16 : 18"
								icon="entries"
							>
								<AText
									:variant="isMobile ? 'topeka' : 'toledo'"
									:modifiers="['semibold']"
									:data-tid="`daily-wheel-amount-${winner?.gameMode}`"
								>
									{{ numberFormat(winner?.data?.entries || 0) }}
								</AText>
							</MPrizeFund>
						</template>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<style scoped lang="scss">
.feed-card {
	height: 90px;
	width: 241px;
	display: flex;
	flex-direction: column;
	position: relative;
	border-radius: 12px;
	padding: 2px;
	overflow: hidden;
	background: transparent;

	@include media-breakpoint-down(md) {
		padding: 1px;
		height: 71px;
		width: 174px;
	}

	.logo {
		border-radius: 12px;
		width: 56px;
		height: 63px;
		object-fit: cover;

		@include media-breakpoint-up(md) {
			width: 64px;
			height: 74px;
		}
	}

	.content {
		display: flex;
		align-items: center;
		height: 100%;
		gap: 16px;
		padding: 6px;
		background: var(--neutral-900);
		border-radius: 10px;
		position: relative;
		z-index: 1;

		@include media-breakpoint-down(md) {
			gap: 12px;
			height: 100%;
			padding: 3px;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
	}

	.winner-name {
		line-height: 18px;

		@include media-breakpoint-down(md) {
			font-size: 10px;
			line-height: 14px;
		}
	}

	.winner-title {
		margin-bottom: 4px;
		@include media-breakpoint-down(md) {
			font-size: 10px;
			line-height: 9px;
		}
	}

	.prize {
		gap: 4px;

		&-pool {
			display: flex;
			gap: 2px;

			&.column {
				flex-direction: column;
			}
		}
	}
}
</style>
